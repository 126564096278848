export const environment = {
  production: false,
  serverUrl: '',
  apiServerUrl: 'https://gakuyu-api.dev.paywith.co.kr',
  fileServerUrl: 'https://gakuyu-api.dev.paywith.co.kr/file',
  keycloakUrl: 'https://keycloak.dev.paywith.co.kr/auth',
  keycloakRealm: 'gakuyu_realm',
  keycloakClientId: 'gakuyu-admin-dev',
  /** GET 요청 중 requestParam 의 키를 object.field 에서 objectField로 변경 */
  searchParamKeyCamelize: '',
};
