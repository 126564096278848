<div>
  <div>{{ year }}.{{ month + 1 }}</div>
  <div>
    <div gdColumns="1fr 1fr 1fr 1fr 1fr 1fr 1fr" class="calendar-day-list">
      <div class="calendar-date">{{ 'sun' | translate }}</div>
      <div class="calendar-date">{{ 'mon' | translate }}</div>
      <div class="calendar-date">{{ 'tue' | translate }}</div>
      <div class="calendar-date">{{ 'wed' | translate }}</div>
      <div class="calendar-date">{{ 'thu' | translate }}</div>
      <div class="calendar-date">{{ 'fri' | translate }}</div>
      <div class="calendar-date">{{ 'sat' | translate }}</div>
    </div>
    <div
      gdColumns="1fr 1fr 1fr 1fr 1fr 1fr 1fr"
      class="calender-date-list"
      *ngFor="let calendarColumnList of calendarArr"
    >
      <div (click)="onDateClick(date)" *ngFor="let date of calendarColumnList">
        <div>
          {{ date | date: 'd' }}
        </div>
        <div [innerHtml]="htmlByDateMap?.get(date)"></div>
      </div>
    </div>
  </div>
</div>
