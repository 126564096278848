import { IMrhstSeatPlan } from '../mrhst-seat-plan/mrhst-seat-plan.model';

/* extFixedValues */
/* /extFixedValues */

/** 두 객체가 같은지 확인할 때 사용할 필드 목록 */
/* model_equalsFields */ export const seatTimetableEqualsFields = ['id'];
/* /model_equalsFields */

/**
 * 좌석 시간표 인터페이스
 */
export interface ISeatTimetable {
  /**
   * id
   */
  id?: number;
  /**
   * mrhstSeatPlan
   */
  mrhstSeatPlan?: IMrhstSeatPlan;
  /**
   * mrhst
   */
  mrhstId?: number;
  /**
   * goods
   */
  goodsId?: number;
  /**
   * start_dttm
   */
  startDttm?: Date;
  /**
   * end_dttm
   */
  endDttm?: Date;
  /**
   * maxMrhstSeatCnt
   */
  maxMrhstSeatCnt?: number;
  /**
   * rsrvMrhstSeatCnt
   */
  rsrvMrhstSeatCnt?: number;
}

/**
 * 좌석 시간표 클래스. 기본값 포함한 객체 생성
 */
export class SeatTimetable implements ISeatTimetable {
  constructor(
    public id?: number,
    public mrhstSeatPlan?: IMrhstSeatPlan | null,
    public mrhstId?: number | null,
    public goodsId?: number | null,
    public startDttm?: Date | null,
    public endDttm?: Date | null,
    public maxMrhstSeatCnt?: number | null,
    public rsrvMrhstSeatCnt?: number | null
  ) {}
}
