import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { map, Observable } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { DialogService } from '../components/dialog/dialog.service';

/**
 * @deprecated
 */
@Injectable({
  providedIn: 'root',
})
export class RouteGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private dialogService: DialogService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const { path } = route.routeConfig;

    if (path === 'logout') {
      this.authService.logout();
      return false;
    }

    if (path === 'logout/:msg') {
      const msg = route.paramMap.get('msg');
      if (msg) {
        return this.dialogService.alert(msg, 'alert').pipe(
          map(() => {
            this.authService.logout();
            return false;
          })
        );
      }
    }

    return true;
  }
}
