<h1 mat-dialog-title>{{ data?.header | translate }}</h1>
<div mat-dialog-content [innerHTML]="data?.body | translate"></div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close (click)="onNo()">
    {{ data?.no || 'BTN.cancel' | translate }}
  </button>
  <button mat-button mat-dialog-close (click)="onYes()">
    {{ data?.yes || 'BTN.ok' | translate }}
  </button>
</div>
