import { Component, OnInit } from '@angular/core';
import * as dayjs from 'dayjs';
import { ISeatTimetable } from 'src/app/repository/seat-timetable/seat-timetable.model';
import { SeatTimetableService } from 'src/app/repository/seat-timetable/seat-timetable.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor(private seatTimetableService: SeatTimetableService) {}

  ngOnInit(): void {}

  // private initSeatTimetable(): void {
  //   const nextDate = dayjs().add(1, 'day');
  //   const lastDateOfNextMonth = nextDate.add(1, 'month').endOf('month');
  //   const rangeStartDttm = nextDate.format('YYYY-MM-DD 00:00:00');
  //   const rangeEndDttm = lastDateOfNextMonth.format('YYYY-MM-DD 23:59:59');

  //   this.seatTimetableService
  //     .findPage({ rangeStartDttm, rangeEndDttm, goodsId: this.goodsId })
  //     .pipe(
  //       tap(({ content }) => {
  //         if (!content?.length) {
  //           this.dialogService.alert('no available lessons').subscribe();
  //           window.history.back();
  //           return;
  //         }

  //         this.goods = content[0].mrhstSeatPlan.goods;
  //         this.seatTimetableList = content;
  //         this.seatTimetableListByDtMap = new Map();

  //         content.forEach((seatTimetable) => {
  //           const startDt = dayjs(seatTimetable.startDttm).format('YYYY-MM-DD');

  //           if (this.seatTimetableListByDtMap.has(startDt)) {
  //             const list = this.seatTimetableListByDtMap.get(startDt);
  //             list.push(seatTimetable);
  //             this.seatTimetableListByDtMap.set(startDt, list);
  //           } else {
  //             this.seatTimetableListByDtMap.set(startDt, [seatTimetable]);
  //           }

  //           this.seatTimetableListByDtMap.forEach((list, dt) => {
  //             this.htmlMap.set(dt, this.getCalendarDateHtml(list));
  //           });
  //         });
  //       })
  //     )
  //     .subscribe();
  // }

  private getCalendarDateHtml(seatTimetableList: ISeatTimetable[]): string {
    let html = `<div>`;

    seatTimetableList.forEach((seatTimetable) => {
      const startTm = dayjs(seatTimetable.startDttm).format('HH:mm');
      const endTm = dayjs(seatTimetable.endDttm).format('HH:mm');
      html += `<div> ${startTm} ~ ${endTm} | ${seatTimetable.rsrvMrhstSeatCnt}/${seatTimetable.maxMrhstSeatCnt} </div>`;
    });

    html += '</div>';

    return html;
  }
}
