import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { mergeMap, Observable, tap } from 'rxjs';
import { PageRepositoryService } from '../abstract-repository.service';
import {
  ISeatTimetable,
  seatTimetableEqualsFields,
} from './seat-timetable.model';

@Injectable({
  providedIn: 'root',
})
export class SeatTimetableService extends PageRepositoryService<ISeatTimetable> {
  baseUri = `api/seatTimetable`;

  equalsFields: string[] = seatTimetableEqualsFields;

  /* extFixedParams */
  /* /extFixedParams */

  constructor(protected http: HttpClient) {
    super(http);
  }

  make(): Observable<any> {
    return this.http.post(`${this.apiServerUrl}/${this.baseUri}/make`, {}).pipe(
      mergeMap(() => super.findPage()),
      tap((r) => {
        console.log(r);
      })
    );
  }
}
