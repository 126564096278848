import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import dayjs from 'dayjs';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit {
  @Input()
  get year(): number {
    return this.#year;
  }

  set year(value: number) {
    if (!value && value !== 0) {
      return;
    }

    const asNumber = Number(value);

    if (typeof asNumber !== 'number') {
      throw new Error('value of year is not number type');
    }

    if (this.#year === asNumber) {
      return;
    }

    this.#year = asNumber;

    if (!this.month && this.month !== 0) {
      return;
    }

    this.initCalendar();
  }

  @Input()
  get month(): number {
    return this.#month;
  }

  /**
   * 0 ~ 11
   */
  set month(value: number) {
    if (!value && value !== 0) {
      return;
    }

    const asNumber = Number(value);

    if (typeof asNumber !== 'number') {
      throw new Error('value of month is not number type');
    }

    if (this.#month === asNumber) {
      return;
    }

    this.#month = asNumber;

    if (!this.year && this.year !== 0) {
      return;
    }

    this.initCalendar();
  }

  @Input() htmlByDateMap: Map<string, string>;

  @Output() dateClick = new EventEmitter();

  calendarArr: string[][];

  #year: number;

  #month: number;

  constructor() {}

  ngOnInit(): void {}

  onDateClick(dt: string): void {
    this.dateClick.emit(dt);
  }

  private initCalendar(): void {
    const firstDateOfMonth = dayjs(new Date(this.year, this.month, 1));
    const daysInMonth = firstDateOfMonth.daysInMonth();
    this.calendarArr = [];

    for (let row = 0; row < 6; row += 1) {
      this.calendarArr[row] = [];
      for (let col = 0; col < 7; col += 1) {
        this.calendarArr[row][col] = null;
      }
    }

    let row = 0;

    for (let date = 1; date <= daysInMonth; date += 1) {
      const theDate = firstDateOfMonth.set('date', date);
      const theDateString = theDate.format('YYYY-MM-DD');
      const theDay = theDate.day();

      this.calendarArr[row][theDay] = theDateString;

      if (theDay === 6) {
        row += 1;
      }

      if (date === daysInMonth) {
        if (this.calendarArr[5][0] === null) {
          this.calendarArr.pop();
        }
      }
    }
  }

  // private makeCalendar(): any[] {
  //   const this.calendarArr = [];
  //   this.calendarRowNum = '20px 60px 60px 60px 60px 60px';
  //   let row = 0;

  //   for (let i = 0; i < 6; i += 1) {
  //     this.calendarArr[i] = [];
  //     for (let j = 0; j < 7; j += 1) {
  //       this.calendarArr[i][j] = null;
  //     }
  //   }

  //   roomInfo.forEach((ri, i) => {
  //     const day = new Date(ri.date).getDay();

  //     this.calendarArr[row][day] = ri;
  //     if (day === 6) {
  //       row += 1;
  //     }

  //     if (i === roomInfo.length - 1) {
  //       if (this.calendarArr[5][0] === null) {
  //         this.calendarArr.pop();
  //       } else {
  //         this.calendarRowNum += ' 60px';
  //       }
  //     }
  //   });
  // }
}
