import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { RouteGuard } from './core/route.guard';

const routes: Routes = [
  { path: 'cpn/new', redirectTo: 'cpn' },
  { path: 'delng/new', redirectTo: 'delng' },
  { path: 'mrhst/new', redirectTo: 'mrhst' },
  { path: 'userInfo/new', redirectTo: 'userInfo' },
  { path: 'cpn/:id/edit', redirectTo: 'cpn/:id' },
  { path: 'cpnIssu/:id/edit', redirectTo: 'cpnIssu/:id' },
  { path: 'delng/:id/edit', redirectTo: 'delng/:id' },
  /* { path: 'userInfo/:id/edit', redirectTo: 'userInfo/:id' }, */
  {
    path: '',
    loadChildren: () =>
      import('./pages/main-layout/main-layout.module').then(
        (m) => m.MainLayoutModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginModule),
  },
  { path: 'logout', canActivate: [RouteGuard], children: [] },
  { path: 'logout/:msg', canActivate: [RouteGuard], children: [] },
  { path: '**', redirectTo: 'ntc' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
      // initialNavigation: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
