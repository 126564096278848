import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { camelCase } from 'lodash-es';
import { PwLocalePipe } from 'pw-lib';
import { LanguageService } from 'src/app/core/services/language.service';

/**
 * 데이터 가공 Pipe
 */
@Pipe({
  name: 'data',
})
export class DataPipe implements PipeTransform {
  constructor(
    private translate: TranslateService,
    private pwLocalePipe: PwLocalePipe,
    private languageService: LanguageService
  ) {}

  // transform(value: any, ...args: string[]): string | string[] {
  /**
   * translatePrefix : 이 값이 있다면 value 값을 번역한다
   * */
  transform(
    value: any,
    type?: string,
    tsType?: string,
    translatePrefix?: string,
    ...args: string[]
  ): string {
    if (value === null || value === undefined) {
      return null;
    }

    // const [type, tsType, translatePrefix] = args;

    if (!type) {
      return value;
    }
    let result: string;
    switch (type) {
      case 'LocalDate':
        result = formatDate(value, 'MM-dd', this.translate.getBrowserLang());
        break;
      case 'LocalTime':
        result = formatDate(value, 'hh:mm:ss', this.translate.getBrowserLang());
        break;
      case 'LocalDateTime':
        result = formatDate(
          value,
          'yyyy-MM-dd hh:mm',
          this.translate.getBrowserLang()
        );
        break;
      case 'Enum':
        result = this.translate.instant(`REPO.ENUM.${tsType}.${value}`);
        break;
      case 'List':
        result = Array.of(value).join(', ');
        break;
      case 'StringList':
        // 서버에는 대문자로 들어가 있을 수도 있지만, 번역키는 무조건 camelCase이므로 변경
        return value?.length > 0
          ? value.map((s) => `${translatePrefix ?? ''}${camelCase(s)}`)
          : '';
      case 'String':
        return this.translate.instant(`REPO.STRING.${camelCase(value)}`, value);
      case 'ListJson':
        try {
          const obj = JSON.parse(value);
          result = Array.of(obj).join(', ');
          // 타입 달라서 결과 값 없으면 파싱한 값 그대로
          if (!result) {
            result = obj;
          }
        } catch (e) {
          // 파싱 실패, 기존 데이터 그대로
          result = value;
        }
        break;
      case 'Map':
        result = Object.entries(value)
          .map(([key, val]) => `${key}: ${val}`)
          .join(', ');
        break;
      case 'MapJson':
        try {
          const obj = JSON.parse(value);
          result = Object.entries(obj)
            .map(([key, val]) => `${this.translate.instant(key)}: ${val}`)
            .join(', ');
          // 타입 달라서 결과 값 없으면 파싱한 값 그대로
          if (!result) {
            result = obj;
          }
        } catch (e) {
          // 파싱 실패, 기존 데이터 그대로
          result = value;
        }
        break;
      case 'Locale':
        result = this.pwLocalePipe.transform(value, this.languageService.lang);
        break;
      case 'Auth':
        result = value;
        break;
      default:
        result = translatePrefix ? translatePrefix + camelCase(value) : value;
    }
    return result;
  }
}
